import styles from "./styles.module.css";

const InputBox = ({ icon,width, type,onChange,value, placeholder }) => {
  return (
    <div  style={{width:width}} className={styles.InputBox}>
      <div className={styles.Icon}>{icon}</div>
      <input onChange={onChange} value={value} type={type} placeholder={placeholder} />
    </div>
  );
};

export default InputBox;
