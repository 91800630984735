import React from 'react'
import styles from './styles.module.css';

const SelectValue = ({text, placeholder, type, value, onChange}) => {
  return (
    <div className={styles.ContainerInput} >
        <p>{text}</p>
        <select className={styles.InputStyles} onChange={onChange} name="" id="">
            <option value="Remote">Remote</option>
            <option value="Onsite">Onsite</option>
            <option value="Hybrid">Hybrid</option>
        </select>
        {/* <input className={styles.InputStyles} placeholder={placeholder} type={type} vlaue={value} onChange={onChange} required /> */}
    </div>
  )
}

export default SelectValue