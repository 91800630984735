import React, {useState,useEffect} from 'react'
import styles from './styles.module.css'
// import axios from 'axios';
import {Link, useLocation, } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../components/elements/Button/Button';
import axios from 'axios'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box
} from '@chakra-ui/react'

const JobDetails = ({data}) => {
    const [val, setVal] = useState(false);
    const [details, setDetails] = useState([]);
    const [isLoading, setLoading] = useState(false);
  
  useEffect(() => {
     const value = JSON.parse(localStorage.getItem('userType'));
     if (value === 'ORGANIZATION') {
       setVal(true);
     } else setVal(false);
   }, [setVal]);

    const location = useLocation();
    const { title, id, description, skills,experience,deadline, locat, companyName, type } = location.state
 
    
  const onSubmit = (e) => {
    e.preventDefault();
  setLoading(true);

    axios({
      method: 'get',
      responseType: 'json',
       headers: {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json',
    'Access-Control-Allow-Headers':
      'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
  },
      url: `https://valuejobs.space:8080/api/v1/jobs/apply?id=${id}`,
    })
      .then(response => {
        setLoading(false);
        sessionStorage.setItem(
          'token',
          JSON.stringify(response?.data?.jwtToken)

        );
        localStorage.setItem('userType',JSON.stringify(response?.data?.userType))
         localStorage.setItem('login', JSON.stringify('true')) 
        toast.success('Successful.');
       
        
      })
      .catch(err => {
        toast.error(err.response.data);
        console.log(err)
        setLoading(false);
      });
  };

  const validate = ( event) => {
    event.preventDefault();
    setLoading(true);

    const input ={
      id:id
    }

    axios({
      method: 'delete',
      responseType: 'json',
      url: `https://valuejobs.space:8080/api/v1/jobs/admin/${id}`,
      data: input,
         headers: {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json',
    'Access-Control-Allow-Headers':
      'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
  },
    })
      .then(response => {
        setLoading(false);
        toast.success(' Successful.');
        console.log(response);
      })
      .catch(err => {
        toast.error(err?.response?.data);
        console.log(err)
        setLoading(false);
      });
  };

  

  // get job response
   const handleResponse = () => {
  setLoading(true);

    axios({
      method: 'get',
      responseType: 'json',
       headers: {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json',
    'Access-Control-Allow-Headers':
      'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
  },
      url: `https://valuejobs.space:8080/api/v1/jobs/getApplicants?jobTitle=${title}`,
    })
      .then(response => {
        setLoading(false);
        setDetails(response.data)     
        
      })
      .catch(err => {
        toast.error(err.response.data);
        console.log(err)
        setLoading(false);
      });
  };

  useEffect(()=>{
    // eslint-disable-next-line
    handleResponse();
    // eslint-disable-next-line
  },[])

   const renderMessages = details?.length===0 ?
             <Tbody>
                <Tr>
                  <Td fontWeight='700' textAlign='center' colSpan='4'>You have No Applicants</Td>
                </Tr>
              </Tbody>
            :
     details?.map(item => {
        return (
             <Tbody>
              <Tr>
                <Td>{item.firstName}</Td>
                <Td>{item.lastName}</Td>
                <Td>{item.email}</Td>
                <Td>{item.dob}</Td>
                <Td>{item.phoneNumber}</Td>
                <Td>{item.location}</Td>
                <Td as='a' href={item.cvUrl} target='_blank' >{item.cvUrl}</Td>
                <Td>{item.currentPosition}</Td>
              </Tr>
            </Tbody>
        
        )
    })
    return (
        <>

                       
                <div key={id} className={styles.Container}>
                    <ToastContainer />
                    <div className={styles.TopContainer}> 
                        <p>{title}</p>
                        <p>{companyName}</p>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Flex}>
                            <p>{locat}</p>
                            <p>{type}</p>
                        </div>
                        <p>{description}</p>
                        <p>{experience}</p>
                        <p>{skills}</p>
                        <div style={{marginTop:'1rem'}} >
                            <p>Application Deadline: {deadline}</p>
                        </div>
                        <Box display='flex' justifyContent='space-between' width='full'>
                       <Box>
                        {
                          val?
                        <Button bg='red' onClick={validate} text={isLoading ? 'Loading. . .':'Delete'} />
                          :
                         <Button onClick={onSubmit} text={isLoading ? 'Loading. . .':'Apply'} />
                         
                        }
                        </Box>
                        <Box>
                          {
                          val?
                          <Link state={{id:id,}} to={`/edit-jobs/${id}`}>
                            <Button bg='Blue'  text='Edit'/>
                          </Link>
                          :
                         null
                         
                        }
                        </Box>                      
                        </Box>
          {
                          val?
                          <Box display='flex' flexDirection='column' w='full' alignItems='center'>
                             <div style={{textAlign:'center'}}  className={styles.Content1}>Job Responses</div>
                            {/* <section className={styles.sectionTable}>
                              <table>
                                <thead><th>Job Title</th>
                                <th>Name</th>
                                <th>Date &amp; Time</th>
                                <th>Email</th>
                                <th>View </th>
                                </thead>
                              </table>
                            </section> */}
                            <TableContainer>
                              <Table variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th>First Name</Th>
                                    <Th>Last Name</Th>
                                    <Th whiteSpace='nowrap'>Email</Th>
                                    <Th>Date of Birth</Th>
                                    <Th>Phone Number</Th>
                                    <Th>Location</Th>
                                    <Th>Cv Url</Th>
                                    <Th>Current Position</Th>
                                  </Tr>
                                </Thead>
                                    {renderMessages                                       
                            }
                              </Table>  
                            </TableContainer>
                          </Box>
                          :
                          null 
                        }
                    </div>
                </div>
             
        
        </>
  )
}

export default JobDetails