import styles from "./styles.module.css";
import Logo from "../../elements/Logo/Logo";
import NavText from "../../elements/NavText/NavText";
import Text from "../../elements/Text/Text";

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.FooterContainer}>
        <Logo />
        <div className={styles.Right}>
          {/* <NavText text="About" to="/about" />
          <NavText text="Jobs" to="/jobs" /> */}
          <NavText text="Contact Us" to={"/contact"} />
          <NavText text="Terms" to='/terms'/>
          <NavText text="Privacy Policy" to='/privacy-policy'/>
        </div>
      </div>
      <div className={styles.LineBreak}></div>
      <Text
        text="© 2023 Powered By Next Project."
        color="#000"
        fontSize={"1rem"}
      />
    </div>
  );
};

export default Footer;
