import React from 'react'
import styles from './styles.module.css';

const ReceivedMessage = () => {
  return (
    <div className={styles.ApplicationContainer}>        
        <p className={styles.Contentapp}>Message Sent from Valuegate Consulting</p>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem'}}>Message Subject</p>
             <p type='text' placeholder='' className={styles.Content2}>Message Subject Goes in Here</p>
            <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem'}}>Message</p>
        <p type='text' placeholder='' className={styles.Content3}>
            message body

Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an 

unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 

typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more 

recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        <a style={{marginTop:'1rem',marginLeft:'.5rem',textDecoration:'none',fontWeight:'700',color:'#000'}} href='/#' download='../../assets/images/dash1.png' >cV_document.pdf</a>
        <div className={styles.Container4} >
            <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'blue',cursor:'pointer',padding:'1rem',borderRadius:'8px',}}>Accept</button>
            <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'red',cursor:'pointer',padding:'1rem',borderRadius:'8px',}} > Reject</button>
        </div>
        {/* <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'blue',cursor:'pointer',padding:'1rem',borderRadius:'8px',}}>Write a message</button>
             */}
    </div>
  )
}

export default ReceivedMessage