import "./App.css";
import React from 'react'
import Footer from "./components/containers/Footer/Footer";

//import NavBarContainer from "./components/containers/NavBarContainer/NavBarContainer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Jobs from "./pages/Jobs/Jobs";
import Login from "./pages/Login/Login";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import Organization from "./pages/Organization/Organization";
import CreateJob from "./pages/CreateJob/CreateJob";
import Notifications from "./pages/Notifications/Notifications";
import Exvolunteer from "./pages/ExVolunteer/Exvolunteer";
import Individual from "./pages/Individual/Individual";
import IndividualDashboard from "./pages/Individual/IndividualDashboard";
//import OrganizationDashboard from "./pages/Organization/OrganizationDashboard";
import Application from "./pages/Organization/Application";
import ListedJobs from "./pages/Organization/ListedJobs";
import SendMessage from "./pages/Organization/SendMessage";
import ReceivedMessage from "./pages/Organization/ReceivedMessage";
import Reset from "./pages/Login/Reset";
import ForgotPassword from "./pages/Login/ForgotPassword";
//import Messages from "./pages/Organization/Messages";
import MessageDetail from "./pages/Organization/MessageDetail";
import PageNotFound from "./components/containers/PageNotFound/404";
import JobDetails from "./pages/JobDetails/JobDetails";
import { Jobss } from "./hooks/api/UserInformationApi";
import { useQuery } from 'react-query';
import Terms from "./components/elements/Terms/Terms";
import Privacy from "./components/elements/Privacy/Privacy";
import Contact from "./pages/ContactUs/ContactUs";
import {
  ChakraProvider,
} from '@chakra-ui/react';
import Navbar from "./components/containers/NavBarContainer/Navbar";
import Update from "./pages/Individual/Update";
import ReplyMessage from "./pages/Organization/ReplyMessage";
import TopFooter from "./components/containers/TopFooter/TopFooter";
import EditJob from "./pages/CreateJob/EditJob";
import Verify from "./pages/Login/Verify";

function App() {
  const [loggedin, setLogin] = React.useState(false);
   // eslint-disable-next-line 
  const { isLoading, data: data } = useQuery('jobs', Jobss);
  return (
    <ChakraProvider>
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path='*' element={<PageNotFound/>} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/organization/messages" element={<SendMessage/>} />
          <Route path="/organization/listed-jobs" element={<ListedJobs />} />
          <Route path="/organization/message-detail" element={<MessageDetail />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path='/jobs/:id' element={<JobDetails isLoading={isLoading} data={data}/>}/>
          <Route path='/edit-jobs/:id' element={<EditJob/>}/>
          <Route path="/login" element={<Login  />} />
          <Route path='/signup/verify' element={<Verify/>} />
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path='/organization' element={<Organization/>}/>
          <Route path='/organization/application' element={<Application/>}/>
          <Route path='/organization/send-message' element={<SendMessage/>}/>
          <Route path='/organization/received-message' element={<ReceivedMessage/>}/>
          <Route path='/organization/dashboard' element={<IndividualDashboard/>}/>
          <Route path='/ex-volunteer' element={<Exvolunteer/>}/>
          <Route path='/individual' element={<Individual/>}/>
          <Route path='/individual/dashboard' element={<IndividualDashboard/>}/>
          <Route path='/create-jobs' element={<CreateJob/>}/>
          <Route path='/notifications' element={<Notifications/>}/>
          <Route path='/terms' element={<Terms/>} />
          <Route path='/privacy-policy' element={<Privacy/>} />
          <Route path='/contact' element={<Contact/>} /> 
          <Route path='/reset' element={<Reset/>}/>
          <Route path='/edit-profile' element={<Update/>}/>
           <Route path='/message' element={<SendMessage/>}/>
           <Route path='/reply-message' element={<ReplyMessage/>}/>
          <Route path='/forgot-password' element={<ForgotPassword/>}/>
          <Route path="/signup" element={<Signup loggedin={loggedin} setLogin={setLogin} />} />
        </Routes>
        <TopFooter/>
        <Footer />
      </Router>
    </div>
    </ChakraProvider>
  );
}

export default App;
