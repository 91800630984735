import React from "react";
import styles from "./styles.module.css";
import { useQuery } from "react-query";
import { Notification } from "../../hooks/api/UserInformationApi";

const Notifications = () => {
  const { isLoading, data: info } = useQuery("info", Notification);
  return (
    <div className={styles.Container}>
      {isLoading ? (
        <p style={{ textAlign: "center" }}>Loading...</p>
      ) : (
        info?.map((infos, i) => {
          return (
            <div key={i} className={styles.NotificationContainer}>
              <p
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  background: "#ccc",
                }}
              ></p>
              <div className={styles.NotificationContent}>
                <p>Available that matches your profile</p>
                <p>{infos?.message}</p>
                <p>www.nextjobs.com</p>
              </div>
            </div>
          );
        })
         ||<p style={{ textAlign: "center" }}>You Have No Notifications</p>
      )}
     

      {/* <div className={styles.NotificationContainer} >
            <p style={{width:'50px',height:'50px',borderRadius:'50%',background:'#ccc'}} ></p>
            <div className={styles.NotificationContent}>
                <p>Available that matches your profile</p>
                <p>This company is looking for your personality to work with, you may just check them out to see if you can work with them</p>
                <p>www.nextjobs.com</p>
            </div>
        </div> */}
    </div>
  );
};

export default Notifications;
