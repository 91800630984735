import React from 'react'
// import image from '../../assets/images/dash1.png'
import styles from "./styles.module.css";

const Dashboard = () => {
  return (
    <div className={styles.Container} >
        {/* <img className={styles.Image} src={image} alt='' /> */}
        <div className={styles.Content} >
            <p>How do you wish to proceed?</p>
        </div>
        <div className={styles.Container2} >
                <a className={styles.Link} href='/organization'>Organization</a>
                <a className={styles.Link} href='/individual'>Individual</a>
           
        </div>
    </div>
  )
}

export default Dashboard