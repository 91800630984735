import styles from "./styles.module.css";

import AboutContainer from "../../components/containers/AboutContainer/AboutContainer";
import HowItWorks from "../../components/containers/HowItWorks/HowItWorks";

const About = () => {
  return (
    <div className={styles.About}>
      <AboutContainer status={true} />
      <HowItWorks />
    </div>
  );
};

export default About;
