import React,{useEffect, useState} from 'react'
import styles from './styles.module.css';
import { useQuery } from 'react-query';
import { IndividualProfile } from '../../hooks/api/UserInformationApi';
import profile from '../../assets/images/profile.png';
import { ToastContainer, toast } from 'react-toastify';
import {Box, Input,Text } from '@chakra-ui/react';
import Button from '../../components/elements/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const IndividualDashboard = () => {
  
    const [val, setVal] = useState(false);
    const [info, setUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    
    const handleChange = e => {
    setUserDetails({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
     const value = JSON.parse(localStorage.getItem('userType'));
     if (value === 'ORGANIZATION') {
       setVal(true);
     } else setVal(false);
   }, [setVal]);
   // eslint-disable-next-line
  const { isLoading, data: user } = useQuery('user',  IndividualProfile);
  React.useEffect(() => {
    setUserDetails(user);
  }, [user]);

  // function to handle delete profile 

  const validate = ( event) => {
    event.preventDefault();
    setLoading(true);

    axios({
      method: 'delete',
      responseType: 'json',
      url: `https://valuejobs.space:8080/api/v1/profile/deleteProfile`,
         headers: {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json',
    'Access-Control-Allow-Headers':
      'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
  },
    })
      .then(response => {
        setLoading(false);
        toast.success(' Successfully Deleted Profile.');
        navigate('/login');
        console.log(response);
      })
      .catch(err => {
        toast.error(err?.response?.data);
        console.log(err)
        setLoading(false);
      });
  };
  return (
    <div className={styles.Container} >
       <ToastContainer />
        <Box w='100%' float='right' mt='1rem' p='1rem' >
          <Link style={{float:'right'}} to='/edit-profile'><Text fontSize='1.2rem' bg='#f2f2f2' borderRadius='4px'p='.5rem' fontWeight='500' float='left'>Edit Profile</Text></Link>
        </Box>
        <div  className={styles.ProfileContainer1} >
            <img className={styles.ProfileImage} src={info?.imageUrl==null?profile:info?.imageUrl } alt='profile' />
            &nbsp; &nbsp; &nbsp;
           <div  style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                 <p className={styles.Welcome}>Welcome,</p>&nbsp;
                 {val?
                <p className={styles.Name}>{info?.name}</p>
                :
                  <p className={styles.Name}>{info?.firstName}</p>
                 }
           </div>
        </div>
        {/* <div className={styles.ProfileContainer}>
            <p className={styles.ProfileContent} > <span style={{color:'#000'}}>Name:</span> &nbsp; { val? info?.name : info?.firstName+' '+info?.lastName } </p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Location:</span> &nbsp; {  info?.location }</p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Contact mail:</span> &nbsp; { info?.email }</p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Website:</span> &nbsp; { val? info?.website : info?.websiteUrl }  </p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Social Media: </span>&nbsp; { info?.linkedInUrl ||info?.website } </p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>About:</span> &nbsp; { val? info?.description : info?.aboutYourself } </p>
                </div> */}
        <Box  className={styles.ProfileContainer} >
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Name:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange}  disabled value={ val? info?.name : info?.firstName+' '+info?.lastName } />
            </Box>
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Location:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} disabled value={ val? info?.location : info?.country } />
            </Box>
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Contact Mail:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} disabled  value={ info?.email } />
            </Box>
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Website:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} disabled  value={ val? info?.website : info?.websiteUrl }/>
            </Box>
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Social Media:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} disabled  value={ info?.linkedInUrl  } />
            </Box>
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>About:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} disabled  value={ val? info?.description : info?.aboutYourself }/>
            </Box>
            <Box mb='.5rem'>
               <Button bg='red' onClick={validate} text={loading ? 'Loading. . .':'Delete'} />
            </Box>
        </Box>
        </div>
  )
}

export default IndividualDashboard