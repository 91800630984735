import styles from "./styles.module.css";
import images from "../../../assets/images/images";
import next from '../../../assets/images/nextimg.png'
import {useState, useEffect} from 'react';
import LargeText from "../../elements/LargeText/LargeText";
import Text from "../../elements/Text/Text";
import SearchInput from "../../elements/SearchInput/SearchInput";

const IntroContainer = () => {
  const [val, setVal] = useState(false);
  useEffect(() => {
     const value = JSON.parse(localStorage.getItem('userType'));
     if (value === 'ORGANIZATION') {
       setVal(true);
     } else setVal(false);
   }, [setVal]);
  return (
    <div className={styles.IntroContainer}>
      <div className={styles.Left}>
        <div className={styles.Header}>
          <LargeText
            text={val? 'Find the best candidate for your organization' : "Find Your NEXT Job or Volunteering Opportunity Here"}
            color="#000"
          />
          <Text
            text="Work or volunteer in-person or remotely for companies & NGOs around the world.."
            color="#BF9C02"
            // textAlign="center"
          />
          <SearchInput placeholder="Search job title" />
        </div>

      </div>
      <div className={styles.Right}>
        <div className={styles.Image}>
          <img src={val? next : images.employee} alt="employee" />
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
