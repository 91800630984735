import styles from "./styles.module.css";
import { useState } from "react";
// import HeaderText from "../../components/elements/HeaderText/HeaderText";
import JobsContainer from "../../components/containers/JobsContainer/JobsContainer";
//import SkillCardContainer from "../../components/containers/SkillCardContainer/SkillCardContainer";
import Search from "../../components/elements/Search/Search";
import Button from "../../components/elements/Button/Button";

const Jobs = () => {
  const [filter, setFilter] = useState('');
  return (
    <>
    <div className={styles.Jobs}>
      {/* <div className={styles.Header}>
        <HeaderText
          text="Available Jobs That Match Your Profile"
          color="#004FF9" 
        />
      </div>
      <SkillCardContainer /> */}
      <Search  setFilter={setFilter} />
      <JobsContainer filter={filter}/>
      <Button
        text={"Find More Jobs"}
        backgroundColor={"#004FF9"}
        color={"#fff"}
      />

    </div>
    </>
  );
};

export default Jobs;
