import EuLogo from '../logo/EU.png';
import dash1 from './dash1.png';
import office from './office.jpeg';
import profile from './profile.png';
import dash2 from './dash2.png';
import dash3 from './dash3.png';
import employee from './employee.jpg';
import roundtable from './roundtable.jpeg';


const  Images = {
    EuLogo,
    dash1,
    dash2,
    dash3,
    employee,
    profile,
    office,
    roundtable
};

export default Images;