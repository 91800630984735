import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const Verify = () => {
  return (
    <Box>
        <Box w='full' h={['100%','80vh']} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Box w='50%' h='50%' display='flex' flexDirection='column' alignItems='center' justifyContent='center' boxShadow='5px 5px 5px #ccc' p='2rem' border='1px solid #333'>
                <Text fontSize='2rem'>
                    Please go to your mail and verify your account.
                </Text>
                <Text _hover={{ textDecoration: 'none' }}
            textAlign="center"
            fontSize={['1rem', '1rem', '.7rem', '1rem']}
            color="#fff"
            bg="#246BFD"
            p=".4rem 1.2rem"
            borderRadius="4px"
            cursor='pointer'
            fontWeight="500"
            _active={{ color: '#000' }}>
                    <Link to='/login'>Login</Link>
                </Text>
            </Box>
        </Box>
    </Box>
  )
}

export default Verify