import React,{useState,useEffect} from 'react'
import styles from './styles.module.css';
import { OrganizationSendMessage } from '../../hooks/api/UserInformationApi';
import { useMutation, useQueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import Messages from './Messages';

const SendMessage = () => {
  const queryClient = useQueryClient();

  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');

    const [val, setVal] = useState(false);
  useEffect(() => {
     const value = JSON.parse(localStorage.getItem('userType'));
     if (value === 'ORGANIZATION') {
       setVal(true);
     } else setVal(false);
   }, [setVal]);


   const { mutate, isLoading } = useMutation(OrganizationSendMessage, {
    onSuccess: data => {
      console.log(data);
      toast.success('Successful! Message sent');
      // const message = "success"
    },
    onError: data => {
      alert(data.response.data||data)
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    }
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const employee = {
      message:message,
      subject:subject,
      receiverUserName:email
    };
    mutate(employee);
  };
  return (
    <div  className={styles.ApplicationContainer}>
    { val?
    <> 
    <ToastContainer />       
        <p className={styles.Contentapp}>Send Message</p>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem',}}>Sender's Email</p>
          <input type='text' placeholder='Enter your email' value={email} onChange={(e)=>setEmail(e.target.value)} className={styles.Content2}/>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem'}}>Subject</p>
          <input type='text' placeholder='' value={subject} onChange={(e)=>setSubject(e.target.value)} className={styles.Content2}/>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem'}}>Message</p>
          <textarea type='text' placeholder='' value={message} onChange={(e)=>setMessage(e.target.value)} className={styles.Content3}/>
          {/* <a style={{marginTop:'1rem',marginLeft:'.5rem',textDecoration:'none',fontWeight:'700',color:'#000'}} href='/#' download='../../assets/images/dash1.png' >cV_document.pdf</a> */}
        {/* <div className={styles.Container4} >
            <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'blue',cursor:'pointer',padding:'1rem',borderRadius:'8px',}}>Accept</button>
            <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'red',cursor:'pointer',padding:'1rem',borderRadius:'8px',}} > Reject</button>
        </div> */}
        <button onClick={onSubmit} style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'blue',cursor:'pointer',padding:'1rem',borderRadius:'8px',}}>{isLoading?'loading...':'Write a message'}</button>
           
    </>
    : null
      }
        <Messages/>
    </div>
  )
}

export default SendMessage