import { useState } from "react";
import styles from "./styles.module.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import Text from "../../components/elements/Text/Text";
import InputBox from "../../components/elements/InputBox/InputBox";
import AuthenticationButton from "../../components/elements/AuthenticationButton/AuthenticationButton";
import {
	AiOutlineEyeInvisible,
	AiOutlineEye,
} from "react-icons/ai";

import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPwd, setShowPwd] = useState(false);

    const togglePassword = (e) => {
		e.preventDefault();
		setShowPwd((prev) => !prev);
	};

let navigate = useNavigate();
  //  end of validation

  const validate = ( event) => {
    event.preventDefault();

    const input = {
    username: email,
      password: password,
    };

    setLoading(true);

    axios({
      method: 'post',
      responseType: 'json',
      url: 'https://valuejobs.space:8080/nextjobs/v1/signin',
      data: input,
    })
      .then(response => {
        setLoading(false);
        sessionStorage.setItem(
          'token',
          JSON.stringify(response?.data?.jwtToken)

        );
        localStorage.setItem('userType',JSON.stringify(response?.data?.userType))
         localStorage.setItem('login', JSON.stringify('true')) 
        toast.success('Sign up Successful.');
        if(response.data.userType === 'NEWUSER'){
          navigate('/dashboard');
          window.location.reload();
        }else if(response.data.userType === 'ORGANIZATION'){
            navigate('/');
            window.location.reload();
        }else if(response.data.userType === 'INDIVIDUAL'){
              navigate('/individual/dashboard');
              window.location.reload();

        }else{
          navigate('/organization/dashboard')
          window.location.reload();
        }
        
      })
      .catch(err => {
        toast.error(err.response.data);
        console.log(err.response)
        setLoading(false);
      });
  };


	// FUNCTION TO HANDLE GOOGLE LOGIN ERROR
	const errorMessage = (error) => {
		console.log(error);
	};

	// FUNCTION TO POST GOOGLE ACCESSTOKEN AND SAVE JWT IN COOKIE
    function onLoginSuccess(response) {

        const credential = {
            accessToken: response.credential
        }

        axios({
          method: 'post',
          responseType: 'json',
          url: 'https://valuejobs.space:8080/nextjobs/v1/google/signin',
          data: credential,
        })
 		.then((response) => {
         setLoading(false);
         sessionStorage.setItem(
           'token',
           JSON.stringify(response?.data?.jwtToken)

         );
         localStorage.setItem('userType',JSON.stringify(response?.data?.userType))
         localStorage.setItem('login', JSON.stringify('true')) 
	 			  if(response.data.userType === 'NEWUSER'){
           navigate('/dashboard');
          
         }else if(response.data.userType === 'ORGANIZATION'){
             navigate('/');
            
         }else if(response.data.userType === 'INDIVIDUAL'){
               navigate('/individual/dashboard');
              

         }else{
           navigate('/organization/dashboard')
          
         }
         window.location.reload();
	 		})
         .catch(err => {
        toast.error(err.response.data);
        setLoading(false);
      });

        setTimeout(navigate("/", 3000))

    }
  


  return (
    <>
    <div className={styles.Login}>
      <div className={styles.Left}>
        <div className={styles.Pole}></div>
        <div className={styles.BarnerRight}>
          <Text text="Login" color="#fff" fontWeight={600} />
        </div>
        <div className={styles.BarnerLeft}></div>
      </div>
      <div className={styles.Right}>
        <Text text="Welcome back" color="#000" fontWeight={600} />
      <InputBox placeholder="EMAIL" onChange={(e)=>setEmail(e.target.value)}  value={email} type="text" />
      <div style={{display:'flex',width:'60%', alignItems:'center'}}>
        <InputBox placeholder="PASSWORD" onChange={(e)=>setPassword(e.target.value)} value={password} 	width='80%'							type={showPwd ? "text" : "password"} />
        <button
        style={{
          height:'50px',
          width:'20%',
          border:'1px solid #264ECA',
          cursor:'pointer',
          margin:'10px 0px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        }}
								onClick={togglePassword}>
								{showPwd ? (
									<AiOutlineEyeInvisible />
								) : (
									<AiOutlineEye />
								)}
							</button>
      </div>
        <ToastContainer />
        {
          loading?
           <AuthenticationButton onClick={validate}   text="loading. . . " />
           :
            <AuthenticationButton onClick={validate}   text={loading ? 'loading. . .':"Login"} />
        }
       <Link to='/forgot-password' style={{textAlign:'center'}}>Forgot Password?</Link>
        <p>Create account <Link to='/signup' style={{textAlign:'center'}}>Sign up </Link></p>
            <GoogleLogin
							onSuccess={onLoginSuccess}
							onError={errorMessage}
						/>
      </div>

    </div>
    </>
  );
};

export default Login;
