import styles from "./styles.module.css";
import {useState, useEffect} from 'react'
import IntroContainer from "../../components/containers/IntroContainer/IntroContainer";
import AboutContainer from "../../components/containers/AboutContainer/AboutContainer";
import RecentJobsContainer from "../../components/containers/RecentJobsContainer/RecentJobsContainer";
import PostJob from "../../components/containers/PostJobContainer/PostJob";

const Home = () => {
   const [val, setVal] = useState(false);
  useEffect(() => {
     const value = JSON.parse(localStorage.getItem('userType'));
     if (value === 'ORGANIZATION') {
       setVal(true);
     } else setVal(false);
   }, [setVal]);
  return (
    <div className={styles.Home}>
      <IntroContainer />
      <AboutContainer to="/about" />
      {
        val ?
        <PostJob/>
        : null
      }
      <RecentJobsContainer />
      
    </div>
  );
};

export default Home;
