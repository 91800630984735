import styles from "./styles.module.css";

import logo from "../../../assets/logo/next_logo.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to='/' className={styles.Logo}>
      <img src={logo} alt="logo" />
    </Link>
  );
};

export default Logo;
