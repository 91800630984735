import React,{useState,} from 'react'
import styles from './styles.module.css';
import { OrganizationSendMessage } from '../../hooks/api/UserInformationApi';
import { useMutation, useQueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import Messages from './Messages';
import { useLocation } from 'react-router-dom';

const ReplyMessage = () => {
  const queryClient = useQueryClient();

  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  
 const location = useLocation()
// eslint-disable-next-line 
  const {   senderUserName } = location?.state
//asdf erty cvbdfef edf czmo

   const { mutate, isLoading } = useMutation(OrganizationSendMessage, {
    onSuccess: data => {
      console.log(data);
      toast.success('Successful! Message sent');
    },
    onError: () => {
      alert("there was an error")
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    }
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const employee = {
      message:message,
      subject:subject,
      receiverUserName:senderUserName
    };
    mutate(employee);
  };
  return (
    <div  className={styles.ApplicationContainer}>
    
    <> 
    <ToastContainer />       
        <p className={styles.Contentapp}>Reply Message</p>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem',}}>Sender's Email</p>
          <input type='text' placeholder='Enter your email' value={senderUserName}  className={styles.Content2}/>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem'}}>Subject</p>
          <input type='text' placeholder='' value={subject} onChange={(e)=>setSubject(e.target.value)} className={styles.Content2}/>
          <p styles={{fontWeight:'600',fontSize:'24px',mb:'1.2rem'}}>Message</p>
          <textarea type='text' placeholder='' value={message} onChange={(e)=>setMessage(e.target.value)} className={styles.Content3}/>
          {/* <a style={{marginTop:'1rem',marginLeft:'.5rem',textDecoration:'none',fontWeight:'700',color:'#000'}} href='/#' download='../../assets/images/dash1.png' >cV_document.pdf</a> */}
        {/* <div className={styles.Container4} >
            <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'blue',cursor:'pointer',padding:'1rem',borderRadius:'8px',}}>Accept</button>
            <button style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'red',cursor:'pointer',padding:'1rem',borderRadius:'8px',}} > Reject</button>
        </div> */}
        <button onClick={onSubmit} style={{color:'#fff',border:'none',marginTop:'1rem',marginLeft:'.5rem',background:'blue',cursor:'pointer',padding:'1rem',borderRadius:'8px',}}>{isLoading?'loading...':'Write a message'}</button>
           
    </>
    
        <Messages/>
    </div>
  )
}

export default ReplyMessage