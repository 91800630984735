import React,{useEffect, useState} from 'react'
import styles from './styles.module.css';
import { useQuery } from 'react-query';
import { IndividualProfile } from '../../hooks/api/UserInformationApi';
//import profile from '../../assets/images/profile.png';
import {Box, Input,Text,Center, Button,useToast } from '@chakra-ui/react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import InputSelect from '../../components/elements/InputBox/InputSelect';

const Update = () => {
  
    const [val, setVal] = useState(false);
    const [info, setUserDetails] = useState([]);

    const [imageUrl, setImageUrl] = useState(null); // Initialize as null
    const [loading, setLoading] = useState('');
      const [country, setCountry] = useState('');
    const toast = useToast();
    const navigate = useNavigate();
    //FUNCTION TO GET AND STORE THE USER INFORMATION IN A STATE
    const handleChange = e => {
    setUserDetails({ ...info, [e.target.name]: e.target.value });
    };
      const changeHandler = value => {
    setCountry(value)
  }
    //FUNCTION TO HANDLE FILE UPLOAD (IMAGE UPLOAD)
   // Create an Axios instance with default configuration (headers).
const api = axios.create({
  baseURL: 'https://valuejobs.space:8080/nextjobs/v1',
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Headers': 'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
  },
});

// Add an Axios interceptor to set the Authorization header globally.
api.interceptors.request.use((config) => {
  const token = JSON.parse(sessionStorage.getItem('token'));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const submitProfileImage = async (file, info) => {
  
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/file/picture/upload?email=${info?.email}`, formData);

    const imageUrl = response.data.imageUrl;
    // Handle success
    setLoading(false);
    setImageUrl(imageUrl);
    // Display success message
    toast({
      title: 'Profile Picture Updated.',
      position: 'top',
      description: 'Your details have been successfully added.',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    navigate('/individual/dashboard');
  } catch (error) {
    // Handle errors
    setLoading(false);
    const errorMessage = error.response?.data || 'An error occurred while uploading the profile picture.';
    toast({
      title: errorMessage,
      position: 'top',
      description: error.response.data,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  }
};

//    useEffect(() => {
//   submitProfileImage(imageUrl); // This will be called when imageUrl changes
//   // eslint-disable-next-line
// }, [imageUrl]);

    //FUNCTION TO SUBMIT INFORMATION TO DATABASE
    const handleSubmit=async(e)=>{
      e.preventDefault();
     submitProfileImage(imageUrl);
      setLoading(true);
      const input ={
        aboutYourself:info.aboutYourself,
        country: country.label,
        location: country.label,
        description: info.description,
        name: info.name,
        firstName: info.firstName,
        lastName: info.lastName,
        phone:info.contact,
        websiteUrl:info.websiteUrl,
        website:info.website,
        linkedInUrl:info.linkedInUrl, 
        imageUrl: ''
      }
      try {
        await
          axios({
      method: 'post',
      responseType: 'json',
       headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
        'Access-Control-Allow-Headers':
        'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
    },
      url: `https://valuejobs.space:8080/api/v1/profile/editProfile`,
     data: input

    })
        .then(response => {
          setLoading(false);
          toast({
            title: 'Profile Updated.',
            position: 'top',
            description: 'Your details have been successfully added.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          navigate('/individual/dashboard');
        });
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Error',
        position: 'top',
        description:
          e.response.data,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    }
  useEffect(() => {
     const value = JSON.parse(localStorage.getItem('userType'));
     if (value === 'ORGANIZATION') {
       setVal(true);
     } else setVal(false);
   }, [setVal]);
   // eslint-disable-next-line
  const { isLoading, data: user } = useQuery('user',  IndividualProfile);
  React.useEffect(() => {
    setUserDetails(user);
  }, [user]);
  return (
    <div className={styles.Container} >
        {/* <div  className={styles.ProfileContainer1} >
            <img className={styles.ProfileImage} src={info?.imageUrl==null?profile:info?.imageUrl } alt='profile' />
            &nbsp; &nbsp; &nbsp;
           <div  style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                 <p className={styles.Welcome}>Welcome,</p>&nbsp;
                 {val?
                <p className={styles.Name}>{info?.name}</p>
                :
                  <p className={styles.Name}>{info?.firstName}</p>
                 }
           </div>
        </div> */}
        {/* <div className={styles.ProfileContainer}>
            <p className={styles.ProfileContent} > <span style={{color:'#000'}}>Name:</span> &nbsp; { val? info?.name : info?.firstName+' '+info?.lastName } </p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Location:</span> &nbsp; {  info?.location }</p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Contact mail:</span> &nbsp; { info?.email }</p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Website:</span> &nbsp; { val? info?.website : info?.websiteUrl }  </p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>Social Media: </span>&nbsp; { info?.linkedInUrl ||info?.website } </p>
            <p className={styles.ProfileContent}><span style={{color:'#000'}}>About:</span> &nbsp; { val? info?.description : info?.aboutYourself } </p>
                </div> */}
        <Box as='form' method="POST" mt='1rem' onSubmit={handleSubmit} className={styles.ProfileContainer} >
            {val?
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Name:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='name' value={val? info?.name : info?.firstName+' '+info?.lastName } />
            </Box>
            :<>
             <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>First Name:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='firstName' value={info?.firstName || ''} />
            </Box>
             <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Last Name:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='lastName' value={info?.lastName || ''} />
            </Box>
            </>
            }
            {
              val?
              <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Location:</Text>
                  <InputSelect
                   // name='location'
                    placeholder="Country"
                    value={country}
                    onChange={changeHandler}
                  />
              {/* <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='location'  value={info?.location} /> */}
            </Box>
            :
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Location:</Text>
              <InputSelect
                    //name='country'
                    placeholder="Country"
                    value={country}
                    onChange={changeHandler}
                  />
              {/* <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='country'  value={info?.country} /> */}
            </Box>
            }
            
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Contact Mail:</Text>
              <Input bg='' color='#333' fontWeight='400'onChange={handleChange} name='email'  disabled  value={info?.email} />
            </Box>
             <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Profile Image:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={(e)=>setImageUrl(e.target.files[0])} type='file'  name='imageUrl' />
            </Box>
            {
              val?
              <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Website:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='website' value={info?.website}/>
            </Box>
            :
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Website:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='websiteUrl' value={info?.websiteUrl}/>
            </Box>
            }
            {
              val?
              null
            :
            <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>Social Media:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='linkedInUrl'  value={info?.linkedInUrl} />
            </Box>
            }
            
            
            {
                val?
                 <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>About:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='description'  value={info?.description }/>
            </Box>
            :
             <Box mb='.5rem'>
              <Text fontWeight='700' color='#333'>About:</Text>
              <Input bg='' color='#333' fontWeight='400' onChange={handleChange} name='aboutYourself'  value={ info?.aboutYourself }/>
            </Box>
            }
           
             <Center>
              {loading ? (
                <Button
                  isLoading
                  mt="2rem"
                  w="full"
                  align="center"
                  p="1rem"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                  }}
                  loadingText="Loading"
                  colorScheme="blue.500"
                  variant="outline"
                />
              ) : (
                <Button
                  bg="blue.500"
                  mt="2rem"
                  w="full"
                  color="#fff"
                  align="center"
                  p="1rem"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </Center>
        </Box>
        </div>
  )
}

export default Update