import React from 'react'
import styles from './styles.module.css';
import {  useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

const InputSelect = ({text, placeholder, type, value, onChange}) => {
    const options = useMemo(() => countryList().getData(), [])
  return (
    <div className={styles.ContainerInput} >
        <p>{text}</p>
         <Select className={styles.SelectInputStyles}   options={options} value={value} onChange={onChange} />
    </div>
  )
}

export default InputSelect