import styles from "./styles.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderText from "../../elements/HeaderText/HeaderText";
import JobsContainer from "../JobsContainer/JobsContainer";
import Button from "../../elements/Button/Button";

const RecentJobsContainer = () => {
  // eslint-disable-next-line 
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const handleClick =(e)=>{
    e.preventDefault();
    navigate('/jobs')
  }
  return (
    <div className={styles.RecentJobsContainer}>
      <div className={styles.Header}>
        <HeaderText text="Recent Jobs" color="#000" />
      </div>
      <div className={styles.InnerContainer}>
        <JobsContainer filter={filter} />
      </div>
      <Button onClick={handleClick} text="See More" />
    </div>
  );
};

export default RecentJobsContainer;
