import styles from "./styles.module.css";

const HeaderText = ({ text, color }) => {
  return (
    <div className={styles.HeaderText} style={{ color: color }}>
      <p>{text}</p>
    </div>
  );
};

export default HeaderText;
