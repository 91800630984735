import { Box, HStack,  Text, Stack, } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import Harmburger from './Hamburger';
import { NavLink,useNavigate } from 'react-router-dom';
import Logo from '../../elements/Logo/Logo';

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const AddBgOnScroll = () => {
    const scrollHeight = 100;
    const pos = window.scrollY;

    if (pos >= scrollHeight) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', AddBgOnScroll);
  }, []);

  const dropDown = useRef(null);

//   changing state of the user; is user logged in or looged out

    const [val, setVal] = React.useState(false);
    const [user, setUser] = React.useState(false);
    const navigate = useNavigate();
    const logout = () => {
      navigate('/login');
      localStorage.setItem('login', JSON.stringify(false));
      sessionStorage.removeItem('token')
      setVal(false);
      
    }
   useEffect(() => {
     const value = JSON.parse(localStorage.getItem('login'));
    const userType = JSON.parse(localStorage.getItem('userType'));
     if (value === 'true') {
       setVal(true);
     } else setVal(false);
     if(userType === 'ORGANIZATION'){
      setUser(true);
     }else setUser(false)
   }, [setVal, setUser]);



  return (
    <Box
      bgColor={navbar ? 'white' : 'white'}
      p={['1rem', '.5rem']}
      w="full"
      fontFamily="Work Sans"
      ref={dropDown}
      boxShadow={navbar ? '0px 2px 8px rgba(74, 74, 104, 0.15)' : '0px 2px 8px rgba(74, 74, 104, 0.15)'}
      transition={navbar ? '0.5s ease' : ''}
      pos="sticky"
      justify="space-between"
      align="center"
      top="0"
      color={navbar ? '#000' : '#000'}
      zIndex={5}
    >
      <Box display={['block', 'none']}>
        <HStack w="full" alignItems="center" justifyContent="space-between">
          <Box mb=".5rem">
            <NavLink to="/">
              <Logo/>
            </NavLink>
          </Box>
          <Box
                display={["block", "none"]}
                zIndex={10}
                alignItems="flex-end"
                onClick={() => setIsOpened((prev) => !prev)}
            >
                <Harmburger isOpened={isOpened} />
            </Box>
        </HStack>
      </Box>
      {
        val === false?
      <Stack
        direction={['column', 'row']}
        pos={['fixed', 'unset']}
        bgColor={['white', 'unset']}
        width={['85%', 'full']}
        left={isOpened ? '0' : '-100%'}
        top="0"
        
        height={['100vh', 'auto']}
        alignItems={['center', 'center']}
        zIndex="3"
        transition={['all .5s ease', 'unset']}
        justifyContent={['center', 'space-between']}
        pl={['1rem', '0']}
        px={['none', '1rem']}
      >
        <NavLink to="/">
          <Logo/>
        </NavLink>
        <Stack
          m="auto"
          display="flex"
          alignItems={['center', 'center']}
          direction={['column', 'row']}
          gap={['2', '10']}
        >
          <NavLink to="">
            <Text
              _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              fontWeight="500"
              p='.5rem'
              _active={{ color: '#000' }}
            >
              Home
            </Text>
          </NavLink>
          <NavLink target='_blank' to="https://next-project.pt/news/">
            <Text
               _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              p='.5rem'
              fontWeight="500"
              _active={{ color: '#000' }}
            >
              About
            </Text>
          </NavLink>
           <NavLink to="/jobs">
            <Text
               _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              p='.5rem'
              fontWeight="500"
              _active={{ color: '#000' }}
            >
              Jobs
            </Text>
          </NavLink>
        </Stack>
        <Stack
          m="auto"
          display="flex"
          alignItems={['center', 'center']}
          direction={['column', 'row']}
          gap={['2', '10']}
        >
          <NavLink to="/login">
            <Text
               _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              fontWeight="500"
              p='.5rem'
              _active={{ color: '#000' }}
            >
              Login
            </Text>
          </NavLink>
          <Text
            as='a'
            href='/signup'
             _hover={{ textDecoration: 'none' }}
            textAlign="center"
            fontSize={['1rem', '1rem', '.7rem', '1rem']}
            color="#fff"
            bg="#246BFD"
            p=".4rem 1.2rem"
            borderRadius="4px"
            cursor='pointer'
            fontWeight="500"
            _active={{ color: '#000' }}
          >
            Get Started
          </Text>
        </Stack>
      </Stack>
      :
      <Stack
        direction={['column', 'row']}
        pos={['fixed', 'unset']}
        bgColor={['white', 'unset']}
        width={['85%', 'full']}
        left={isOpened ? '0' : '-100%'}
        top="0"
        
        height={['100vh', 'auto']}
        alignItems={['center', 'center']}
        zIndex="3"
        transition={['all .5s ease', 'unset']}
        justifyContent={['center', 'space-between']}
        pl={['1rem', '0']}
        px={['none', '1rem']}
      >
        <NavLink to="/">
          <Logo/>
        </NavLink>
        <Stack
          m="auto"
          display="flex"
          alignItems={['center', 'center']}
          direction={['column', 'row']}
          gap={['2', '10']}
        >
          <NavLink to="">
            <Text
               _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              fontWeight="500"
              p='.5rem'
              _active={{ color: '#000' }}
            >
              Home
            </Text>
          </NavLink>
          <NavLink to={user?'/organization/listed-jobs':"/jobs"}>
            <Text
              _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
               p='.5rem'
              fontWeight="500"
              _active={{ color: '#000' }}
            >
              Jobs
            </Text>
          </NavLink>
           <NavLink to={user? "/organization/messages":"/message"}>
            <Text
              _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              p='.5rem'
              fontWeight="500"
              _active={{ color: '#000' }}
            >
              Messaging
            </Text>
          </NavLink>
           <NavLink to="/notifications">
            <Text
              _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              p='.5rem'
              fontWeight="500"
              _active={{ color: '#000' }}
            >
              Notifications
            </Text>
          </NavLink>
           <NavLink to={user? '/organization/dashboard' : '/individual/dashboard'}>
            <Text
              _hover={{ textDecoration: 'none' ,bg:'#004FF9',color:'#fff',padding:'.5rem',borderRadius:'4px'}}
              textAlign={['center', 'none']}
              fontSize={['1rem', '1rem', '.7rem', '1rem']}
              color="#000"
              p='.5rem'
              fontWeight="500"
              _active={{ color: '#000' }}
            >
              Account
            </Text>
          </NavLink>
        </Stack>
        <Stack
          m="auto"
          display="flex"
          alignItems={['center', 'center']}
          direction={['column', 'row']}
          gap={['2', '10']}
        >
          <Text
            _hover={{ textDecoration: 'none' }}
            textAlign="center"
            fontSize={['1rem', '1rem', '.7rem', '1rem']}
            color="#fff"
            bg="#246BFD"
            p=".4rem 1.2rem"
            borderRadius="4px"
            
            fontWeight="500"
            _active={{ color: '#000' }}
            cursor='pointer'
            onClick={logout}
          >
            Logout
          </Text>
        </Stack>
      </Stack>
}
    </Box>
  );
};

export default Navbar;