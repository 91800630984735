import styles from "./styles.module.css";

const LargeText = ({ text, color }) => {
  return (
    <div className={styles.LargeText} style={{ color: color }}>
      <p>{text}</p>
    </div>
  );
};

export default LargeText;