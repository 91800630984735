import React,{useState} from 'react'
import styles from "./styles.module.css";
import rec from '../../assets/images/Rectangle 228.png'
import InputValue from '../../components/elements/InputBox/InputValue';
import TextValue from '../../components/elements/InputBox/TextArea';
import SelectValue from '../../components/elements/InputBox/SelectValue';
import { CreateJobsData } from '../../hooks/api/UserInformationApi';
import { useMutation, useQueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateJob = () => {
    const [deadline, setDeadline] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [experience, setExperience] = useState('');
    const [location, setLocation] = useState('');
    const [skills, setSkills] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [website, setWebsite] = useState('');

     const navigate = useNavigate();

    const queryClient = useQueryClient();
  
     const { mutate, isLoading } = useMutation(CreateJobsData, {
    onSuccess: data => {
      console.log(data);
      toast.success('Successful! Profile Created');
      
      navigate('/organization/dashboard');
    },
    onError: () => {
      alert("there was an error")
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    }
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const employee = {
      applicationDeadline:deadline,
      description:description,
      companyEmailAddress:email,
      location:location,
      companyName:name,
      experience:experience,
      website:website,
      skills:skills,
      title:title,
      type:type
    };
    mutate(employee);
  };
  

  return (
    <div  className={styles.Container}>
        <div className={styles.Container2}>
            <p>Create a Job Offer</p>
            <img src={rec} alt='' />
        </div>
        <div className={styles.Container3}>
            <div className={styles.Box} >
                <p style={{color:'#000', fontWeight:'700'}} >Create job offer </p>
                <p style={{color:'#000'}} >Exciting Opportunity: Join our dynamic team! We're seeking skilled individuals to fill key positions in our company. Competitive salaries, comprehensive benefits, and career growth await you. Be part of an innovative environment where your talents are valued. Apply now and take the first step towards a fulfilling career with us!</p>
            </div>
            <div className={styles.Box}>
                <p style={{color:'#000', fontWeight:'700'}}>Recruit  Top People</p>
                <p style={{color:'#000'}}>"Discover Top Talent: Unlock your organization's potential with our expert recruitment services. We specialize in identifying and attracting top-notch professionals who align perfectly with your company's vision and goals. From extensive candidate screening to seamless onboarding, we ensure you get the best talent to drive your business forward. Let's build greatness together!</p>
            </div>
            <div className={styles.Box}>
                <p style={{color:'#000', fontWeight:'700'}}>Quick & Easy</p>
                <p style={{color:'#000'}}>Quick & Easy: Streamline your tasks with our efficient solutions. Save time and effort as we simplify complex processes, providing user-friendly tools and services. Experience seamless integration and smooth workflows, empowering you to focus on what matters most. Say goodbye to hassle and embrace simplicity with our reliable Quick & Easy solutions.</p>
            </div>
        </div>
        <div className={styles.Container4} >
            <ToastContainer />
            <div className={styles.InputBox} >
                <InputValue value={name} onChange={(e)=>setName(e.target.value)} text='company name' type='text' placeholder='company name'/>
                <InputValue value={email} onChange={(e)=>setEmail(e.target.value)} text='company email' type='text' placeholder='company@mail.com'/>
            </div>
            <div className={styles.InputBox} >
                <TextValue value={description} onChange={(e)=>setDescription(e.target.value)} text='description' type='text' placeholder='description'/>
                <InputValue value={experience} onChange={(e)=>setExperience(e.target.value)} text='experience' type='text' placeholder='experience'/>
            </div>
            <div className={styles.InputBox} >
                <InputValue value={location} onChange={(e)=>setLocation(e.target.value)} text='location' type='text' placeholder='location'/>
                <InputValue value={title} onChange={(e)=>setTitle(e.target.value)} text='title' type='text' placeholder='title'/>
            </div>
            <div className={styles.InputBox} >
                <InputValue value={skills} onChange={(e)=>setSkills(e.target.value)} text='skills' type='text' placeholder='skills'/>
                <SelectValue  onChange={(e)=>setType(e.target.value)} text='type' type='text' placeholder='type'/>
            </div>
            <div className={styles.InputBox} >
                <InputValue value={website} onChange={(e)=>setWebsite(e.target.value)} text='website' type='text' placeholder='website'/>
                <InputValue value={deadline} onChange={(e)=>setDeadline(e.target.value)} text='application deadline' type='date' placeholder='application deadline'/>
            </div>
             <button onClick={onSubmit}   style={{padding:'1rem',width:'40%',borderRadius:'8px',marginTop:'1rem',marginBottom:'1rem',fontSize:'1.3rem',border:'none',background:'#1d4ed8',color:'#fff',cursor:'pointer',transition:'ease-in'}} >{isLoading?'loading...':'Submit'}</button>
        </div>
       
    </div>
  )
}

export default CreateJob