import styles from "./styles.module.css";

const Text = ({
  text,
  color,
  textAlign,
  fontSize,
  fontWeight,
  marginBottom,
}) => {
  return (
    <div
      className={styles.Text}
      style={{
        color: color,
        textAlign: textAlign,
        fontSize: fontSize,
        fontWeight: fontWeight,
        marginBottom: marginBottom,
      }}
    >
      <p>{text}</p>
    </div>
  );
};

export default Text;
