import styles from "./styles.module.css";

const Button = ({ text, onClick, bg }) => {
  return (
    <button className={styles.Button} style={{background:bg}} onClick={onClick}>
      
      {text}
    </button>
  );
};

export default Button;
