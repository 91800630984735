import axios from "axios";

const userInformationApi = axios.create({
  baseURL: "https://valuejobs.space:8080",
  headers: {
    Authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem("token"))}`,
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json",
    "Access-Control-Allow-Headers":
      "x-access-token, Origin, X-Requested-With, Content-Type, Accept",
  },
});

export const Signup = async () => {
  const response = await userInformationApi.get(`/nextjobs/v1/signup`);
  return response.data;
};
export const Signin = async () => {
  const response = await userInformationApi.get(`/nextjobs/v1/signin`);
  return response.data;
};

export const OrganizationAllJobs = async () => {
  const response = await userInformationApi.get(
    `/api/v1/jobs/getAllByOrganization`
  );
  return response.data;
};

export const OrganizationMessages = async () => {
  const response = await userInformationApi.get(
    `/api/v1/Messages/getReceivedMessage`
  );
  return response.data;
};

export const OrganizationSendMessage = async (data) => {
  const { data: response } = await userInformationApi.post(
    "/api/v1/Messages/add",
    data
  );
  return response.data;
};

export const Jobss = async () => {
  const response = await userInformationApi.get(`/api/v1/jobs/0`);
  return response.data;
};

export const JobsSearch = async (data) => {
  const { data: response } = await userInformationApi.post(
    "/api/v1/jobs/search",
    data
  );
  return response.data;
};

export const Notification = async () => {
  const response = await userInformationApi.get(
    `/api/v1/notifications/getNotifications?limit=50&offset=0`
  );
  return response.data;
};

export const JobsApply = async (data) => {
  const { data: response } = await userInformationApi.get(
    "/api/v1/jobs/apply",
    data
  );
  return response.data;
};

export const IndividualProfile = async () => {
  const response = await userInformationApi.get(
    "/api/v1/profile/getIndividualProfile"
  );
  return response.data;
};

export const Exvolunter = async (data) => {
  const { data: response } = await userInformationApi.post(
    "/api/v1/profile/exVolunteer/add",
    data
  );
  return response.data;
};

export const IndividualData = async (data) => {
  const { data: response } = await userInformationApi.post(
    "/api/v1/profile/individual/add",
    data
  );
  return response.data;
};

export const OrganizationData = async (data) => {
  const { data: response } = await userInformationApi.post(
    "/api/v1/profile/organization/add",
    data
  );
  return response.data;
};

export const CreateJobsData = async (data) => {
  const { data: response } = await userInformationApi.post(
    "/api/v1/jobs/admin/add",
    data
  );
  return response.data;
};

export default userInformationApi;
