import styles from "./styles.module.css";

import { Link } from "react-router-dom";

const NavText = ({ text, backgroundColor,onClick, color, to,minWidth }) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        color: color || "#000",
        width:'100%'

      }}
      onClick={onClick}
    >
      <div
        className={styles.NavText}
        style={{ backgroundColor: backgroundColor, whiteSpace:'nowrap',color: color, minWidth:minWidth }}
      >
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavText;
