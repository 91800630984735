import React from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query';
import { OrganizationMessages } from '../../hooks/api/UserInformationApi'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'


const Messages = () => {
// eslint-disable-next-line 
  const { isLoading, data: msg } = useQuery('msg', OrganizationMessages);
    const renderMessages = msg?.length===0 ?
             <Tbody>
              <Tr>
                <Td>nil</Td>
                <Td>nil</Td>
                <Td>nil</Td>
                <Td>You have No Message</Td>
              </Tr>
            </Tbody>
            :
     msg?.map(item => {
        return (
             <Tbody>
              <Tr>
                <Td>{item.senderFullName}</Td>
                <Td>{item.subject}</Td>
                <Td>09-01-2020 | 09:30</Td>
                <Td><Link style={{textDecoration:'none'}} to={"/organization/message-detail"} state={{...item}}>Open Message</Link>*</Td>
              </Tr>
            </Tbody>
        
        )
    })
  return (
      <div className={styles.MessageContainer}> 
        <h1 style={{color:'#004FF9',fontWeight:700,marginBottom:'2rem',fontSize:'1.5rem'}}>Messages</h1>
        <TableContainer>
  <Table variant='simple'>
    <Thead>
      <Tr>
        <Th>From</Th>
        <Th>Subject</Th>
        <Th whiteSpace='nowrap'>Date & Time</Th>
        <Th></Th>
      </Tr>
    </Thead>
        {renderMessages||
            <Tbody>
              <Tr>
                <Td fontWeight='700' textAlign='center' colSpan='4'>You have No Message</Td>
              </Tr>
            </Tbody>
}
  </Table>  
</TableContainer>
        
         {/* <table>
            <thead>
              <th>From</th>
              <th>Subject</th>
              <th>Date &amp; Time</th>
              <th></th>
            </thead>
            { msg?.lenght===0?
             <tbody>
                <td>nil</td>
                <td>nil</td>
                <td>nil</td>
                <td className={styles.tdBtnCont}>nil</td>
            </tbody>
            : renderMessages}
            </table> */}
       </div>
    
  )
}

export default Messages
