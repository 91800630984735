import React from 'react'
import styles from './styles.module.css';

const TextValue = ({text, placeholder, type, value, onChange}) => {
  return (
    <div className={styles.ContainerInput} >
        <p>{text}</p>
        <textarea style={{height:'4rem'}} className={styles.InputStyles} size="4" placeholder={placeholder} type={type} vlaue={value} onChange={onChange} required />
    </div>
  )
}

export default TextValue