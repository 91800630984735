import React, {useState} from 'react';
import {Text, Spinner} from '@chakra-ui/react'
import styles from './styles.module.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export default function Contact() {

    const [loading, setLoading] = useState(false);
    const [firstname, setFirstName] = useState('');
     const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');


    const validate = ( event) => {
    event.preventDefault();

    const input = {
        firstName: firstname,
        lastName: lastname,
        email: email,
        phone: phone,
        message: message
    };

    setLoading(true);

    axios({
      method: 'post',
      responseType: 'json',
      url: 'https://valuejobs.space:8080/nextjobs/v1/signup/sendSupportMail',
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast.success(' Successful.');
      })
      .catch(err => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };
  return (
    <>

        <section className={styles.contactSect}>
            
            <section className={styles.contactUs}>
                <div className={styles.contactOne}>
                    <ToastContainer />
                    <Text fontWeight='700' fontSize='1.5rem'>Contact Information</Text>
                    <p>Fill up the form and our Team will get back to you within 24 hours or contact us directly.</p>

                    <div className={styles.contactOneInner}>
                        {/* <p><i className="fa-solid fa-phone"></i> +36 20 333 7650</p> */}

                        <p style={{color:'#fff'}}><i className="fa-solid fa-envelope"></i> support@next-paths.com</p>

                        {/* <p><i className="fa-solid fa-location-dot"></i> 104 street 1028 Budapest. Hungary</p> */}
                    </div>

                    <div className={styles.contactTwoInner}>
                        <button><i className="fa-brands fa-facebook-f"></i></button>
                        <button><i className="fa-brands fa-twitter"></i></button>
                        <button><i className="fa-brands fa-instagram"></i></button>
                        <button><i className="fa-brands fa-linkedin-in"></i></button>
                    </div>
                </div>

                <div className={styles.contactTwo}>
                    <div className={styles.info}>
                        <Text mt='1rem' mb='1rem' textAlign='justify'>Any questions, or remarks? Just write us a message</Text>
                    </div>
                    
                    <div className={styles.formDiv}>
                        <form className={styles.contactForm}>
                            <label htmlFor='firstName'>First Name</label>
                            <input type={'text'} placeholder={'John'} name={'firstName'} onChange={(e)=>setFirstName(e.target.value)} value={firstname} className={styles.firstName} />

                            <label htmlFor='lastName'>Last Name</label>
                            <input type={'text'} placeholder={'Doe'} name={'lastName'} onChange={(e)=>setLastName(e.target.value)} value={lastname} className={styles.lastName} />

                            <label htmlFor='email'>Email</label>
                            <input type={'email'} placeholder={'johndoe@yahoo.com'} onChange={(e)=>setEmail(e.target.value)} value={email} name={'email'} className={styles.email} />

                            <label htmlFor='phone'>Phone</label>
                            <input type={'tel'} placeholder={'+36 20 454 2051'} onChange={(e)=>setPhone(e.target.value)} value={phone} name={'phone'} className={styles.phone} />

                            <label htmlFor='message'>Message</label>
                            <textarea style={{fontSize:'.9rem'}} placeholder={'Write your message...'} onChange={(e)=>setMessage(e.target.value)} value={message} name={'message'} className={styles.message} />

                            <button style={{padding:'1rem'}} onClick={validate} >
                                {
                                    loading?<Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='md'
                                    />
                                    :'Send Message'
                                }
                                </button>
                        </form>
                    </div>
                </div>
            </section>
            
        </section>

    </>
  )
}
