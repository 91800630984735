import React from 'react'
import styles from './styles.module.css'
import { Link, useLocation } from 'react-router-dom'
import Messages from './Messages'

const MessageDetail = () => {
 const location = useLocation()
// eslint-disable-next-line 
  const { subject, message, senderFullName, senderUserName } = location?.state
  return (
    <div style={{minHeight:'80vh'}} className={styles.msgDetCont}>
          <div className={styles.headerContainer}>
            <p>Message from organization</p>
          </div>
          <div className={styles.contentCont}>
            <p>{subject}</p>
            <div className={styles.subject}>
              <p>{`Message from ${senderFullName}`}</p>
              </div>
              <p>Message Content</p>
              <div className={styles.content}>
              <p> {message}</p>
            </div>
              <div className={styles.btnCont}>
                <Link to="/reply-message" state={{senderUserName:senderUserName}} className={styles.reply}>Reply</Link>
                <Link to="/organization/message-detail" className={styles.delete}>Delete</Link>
              </div>
          </div>
          <Messages/>
      <section>
      </section>
    
    </div>
  )
}

export default MessageDetail
