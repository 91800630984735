import React from 'react'
import styles from './styles.module.css'
import image from '../../../assets/images/img404.svg'

const PageNotFound = () => {
  return (
    <div className={styles.Container}>
        <p>Page Not Found</p>
        <img src={image} alt='' />
    </div>
  )
}

export default PageNotFound