import styles from "./styles.module.css";

import HeaderText from "../../elements/HeaderText/HeaderText";
import images from "../../../assets/images/images";

import Text from "../../elements/Text/Text";
import Button from "../../elements/Button/Button";

import { useNavigate } from "react-router-dom";

const AboutContainer = ({ status }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/about");
  };
  return (
    <div className={styles.AboutContainer}>
      <HeaderText text="About Us" color="#ffffff" />
      <div className={styles.InnerContainer}>
        <div className={styles.Left}>
          <div className={styles.Header}>
            <Text
              text="Next-Path is an online job portal that enables job seekers to find their dream jobs and connects recruiters with candidates with the right skills. Applicants can post their Resumes, research companies and message the recruiters directly. Recruiters and organizations use NEXT JOB to track and sort applicants."
              color="#ffffff"
              marginBottom={"20px"}
              fontSize={"16px"}
              textAlign={"justify"}
            />
            <Text
              text="They search our resume database regularly that’s why applicants should upload their resume for free, update their profile and connect with their Google and LinkedIn accounts. Our mission is to become the go-to-place for all job searches where every applicant gets the ideal job and organizations get the best value. We help bridge the gap between job seekers and employers."
              color="#ffffff"
              fontSize={"16px"}
              textAlign={"justify"}
            />
            {status ? null : <Button text="Read More" onClick={handleClick} />}
          </div>
        </div>
        <div className={styles.Right}>
          <div className={styles.Image}>
            <img src={images.roundtable} alt="employee" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContainer;
