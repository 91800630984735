import { useState } from "react";
import styles from "./styles.module.css";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Text from "../../components/elements/Text/Text";
import InputBox from "../../components/elements/InputBox/InputBox";
import AuthenticationButton from "../../components/elements/AuthenticationButton/AuthenticationButton";
import axios from 'axios';
import { useNavigate,Link } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const Signup = ({loggedin, setLogin}) => {

    const [loading, setLoading] = useState(false);
    const [firstname, setFirstName] = useState('');
     const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

let navigate = useNavigate();
  //  end of validation

  const validate = ( event) => {
    event.preventDefault();

    const input = {
        firstName: firstname,
        lastName: lastname,
        email: email,
      password: password,
    };

    setLoading(true);

    axios({
      method: 'post',
      responseType: 'json',
      url: 'https://valuejobs.space:8080/nextjobs/v1/signup',
      data: input,
    })
      .then(response => {
        setLoading(false);
        sessionStorage.setItem(
          'token',
          JSON.stringify(response?.data?.message)
        );
        toast.success('Sign up Successful.');
        console.log(response);
        navigate('/signup/verify');
      })
      .catch(err => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };

  // GOOGLE SIGNUP
  function onLoginSuccess(response) {

        const credential = {
            accessToken: response.credential
        }

        axios({
          method: 'post',
          responseType: 'json',
          url: 'https://valuejobs.space:8080/nextjobs/v1/google/signin',
          data: credential,
        })
        .then(response =>{
            toast.success('Sign up Successful.');
            console.log(response);
            navigate('/login');
        })
         .catch(err => {
        toast.error(err.response.data);
        setLoading(false);
      });

        setTimeout(navigate("/", 3000))

    }
    
    function onLoginFailure() {
        console.log('Login Failed');
    }


  return (
    <>
    <div className={styles.Login}>
      <div className={styles.Left}>
        <div className={styles.Pole}></div>
        <div className={styles.BarnerRight}>
          <Text text="Sign up" color="#fff" fontWeight={600} />
        </div>
        <div className={styles.BarnerLeft}></div>
      </div>
      <div className={styles.Right}>
        <ToastContainer />
        <Text text="Lets set up your Account" color="#004FF9" fontWeight={700} />
        <InputBox placeholder="FIRST NAME" onChange={(e)=>setFirstName(e.target.value)} value={firstname} type="text" />
        <InputBox placeholder="LAST NAME" onChange={(e)=>setLastName(e.target.value)} value={lastname} type="text" />
        <InputBox placeholder="EMAIL" onChange={(e)=>setEmail(e.target.value)} value={email} type="text" />
        <InputBox placeholder="PASSWORD" onChange={(e)=>setPassword(e.target.value)} value={password} type="password" />
        <InputBox placeholder="RETYPE PASSWORD" type="password" />
        {loading?
        <AuthenticationButton text="loading. . ." onClick={validate}  />
    :
    <AuthenticationButton text="Signup" onClick={validate}  />}
    <p>Already have an account? <Link to='/login' style={{textAlign:'center'}}>Login</Link></p>
             <GoogleOAuthProvider clientId="268079797838-7s7lvtbsrrjvh18j903de9nsve0bbkol.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={onLoginSuccess}
                                onError={onLoginFailure}
                            />
                        </GoogleOAuthProvider>
      </div>

    </div>
    </>
  );
};

export default Signup;
