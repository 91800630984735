import styles from "./styles.module.css";
import { Link } from "react-router-dom";

const JobCard = ({
  companyName,
  jobTitle,
  description,
  id,
  skills,
  location,
  type,
  jobContent,
  price,
}) => {
  return (
    <div className={styles.JobCard}>
      <p className={styles.JobTitle}>{jobTitle}</p>
      <div>
        <p className={styles.CompanyName}>{companyName}</p>
      </div>
      <div>
        {/* <p className={styles.JobContent} >{jobContent}</p> */}
        <p className={styles.JobContent}>{description}</p>
        <p className={styles.JobContent}>Skills: {skills}</p>
        {/* <p className={styles.JobContent} >Job Type: {type}</p> */}
        <div className={styles.ViewJob}>
          {/* <p className={styles.Price} >Deadline: {price}</p> */}
          <Link
            className={styles.Link}
            state={{
              title: jobTitle,
              id: id,
              type: type,
              company: companyName,
              locat: location,
              description: description,
              skills: skills,
            }}
            to={`/jobs/${id}`}
          >
            <p>View Job</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
