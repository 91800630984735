import React from 'react'
import styles from './styles.module.css'
import {Link} from 'react-router-dom'
import { OrganizationAllJobs } from '../../hooks/api/UserInformationApi'
import { useQuery } from 'react-query'
import JobCard from '../../components/elements/JobCard/JobCard'
import { Box } from '@chakra-ui/react'

const ListedJobs = () => {
   // eslint-disable-next-line 
  const { isLoading, data: jobs } = useQuery('jobs', OrganizationAllJobs);
  return (
    <div className={styles.OrgainizationContaine}>
        <h1 style={{textAlign:'center'}} className={styles.Content1}>Job Listings Created By You & Other Organizations</h1>
        <Box w='full' display='flex' alignItems='center' justifyContent='center'>
        <Link style={{textAlign:'center'}} to='/create-jobs' className={styles.button}>post a job</Link>
        </Box>
      <div className='big-text'>
      </div>
      <div className={styles.searchDiv}>
      <div className={styles.search}>
        <input type="text" placeholder='search job title' name='tile'/>
        <input type="text" placeholder='search location' name='location'/>
        <button className={styles.searchButton}>search</button>
      </div>
      </div>
      <div className='smaller-text'>
        <h2 className={styles.Content1}>Job Listings You Have Created</h2>
      </div>
      <div className={styles.jobsdiv}>
         {
          jobs?.length === 0 ?  <p className={styles.jobmsg}>you have no jobs</p>:
          
          jobs?.map((job,i) => {
        return <JobCard key={i} id={job.id} skills={job.skills} type={job.type} description={job.experience} companyName={job.location} price={job.applicationDeadline} jobContent={job.description} jobTitle={job.title} />;
          
      })} 
          </div>
      
     
    </div>
  )
}

export default ListedJobs
