import React from 'react'
import {Link}from 'react-router-dom';
import styles from "./styles.module.css";

const PostJob = () => {
  return (
    <div className={styles.Container}>
        <p style={{color:'#000',fontWeight:'700',fontSize:'2rem'}}>Ready for your next hire?</p>
        <Link className={styles.Link} to='/create-jobs'>Post a job</Link>
    </div>
  )
}

export default PostJob