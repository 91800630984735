import { useState } from "react";
import styles from "./styles.module.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import {  useNavigate } from "react-router-dom";
import Text from "../../components/elements/Text/Text";
import InputBox from "../../components/elements/InputBox/InputBox";
import AuthenticationButton from "../../components/elements/AuthenticationButton/AuthenticationButton";
const Reset = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [text, setText] = useState('')
    const [password, setPassword] = useState('')

let navigate = useNavigate();
  //  end of validation

  const validate = ( event) => {
    event.preventDefault();

    const input = {
    userame: email,
    text: text,
    password: password,
    };

    setLoading(true);

    axios({
      method: 'post',
      responseType: 'json',
      url: 'https://valuejobs.space:8080/nextjobs/v1/signup/recover/second',
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast.success('Sign up Successful.');
        console.log(response);
        navigate('/login');
      })
      .catch(err => {
        toast.error(err.message);
        console.log(err)
        setLoading(false);
      });
  };

  return (
    <>
    <div className={styles.Login}>
      <div className={styles.Left}>
        <div className={styles.Pole}></div>
        <div className={styles.BarnerRight}>
          <Text text="Reset" color="#fff" fontWeight={600} />
        </div>
        <div className={styles.BarnerLeft}></div>
      </div>
      <div className={styles.Right}>
        <Text text="Reset" color="#000" fontWeight={600} />
      <InputBox placeholder="EMAIL" onChange={(e)=>setEmail(e.target.value)} value={email} type="text" />
      <InputBox placeholder="TEXT" onChange={(e)=>setText(e.target.value)} value={text} type="text" /> 
        <InputBox placeholder="PASSWORD" onChange={(e)=>setPassword(e.target.value)} value={password} type="password" />
        <ToastContainer />
        {
          loading?
           <AuthenticationButton onClick={validate}   text="loading. . . " />
           :
            <AuthenticationButton onClick={validate}   text="Reset" />
        }
      </div>
    </div>
    </>
  );
};

export default Reset;
