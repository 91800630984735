import styles from "./styles.module.css";

import HeaderText from "../../elements/HeaderText/HeaderText";
import office from "../../../assets/images/office.jpeg";

import Text from "../../elements/Text/Text";

const HowItWorks = () => {
  return (
    <div className={styles.HowItWorks}>
      <HeaderText text="How It Works" color="#004FF9" />
      <div className={styles.InnerContainer}>
        <div className={styles.Left}>
          <div className={styles.Header}>
            <Text
              text="Grow your career with NextPaths.
We have made it easier to find your dream job. All you need to do is register, complete your profile, upload your resume and we’ll match you with your dream job.
Update your profile with Facebook, Google and LinkedIn to improve your chances of landing interviews with top companies.
As an organization, register, complete your profile, and create some job listings for higher chances of being sought after by competent applicants."
              color="#004FF9"
              fontSize={"16px"}
              textAlign="justify"
            />
          </div>
        </div>
        <div className={styles.Right}>
          <div className={styles.Image}>
            <img src={office} alt="employee" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
