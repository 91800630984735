import React from 'react'
import styles from './styles.module.css';

const InputValue = ({text, placeholder, type, value,name, onChange}) => {
  return (
    <div className={styles.ContainerInput} >
        <p>{text}</p>
        <input className={styles.InputStyles} placeholder={placeholder} name={name} type={type} value={value} onChange={onChange} required />
    </div>
  )
}

export default InputValue