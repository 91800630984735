import styles from "./styles.module.css";
import {useState} from 'react'
// import jobs from "../../../Demo/Api/jobs";

import JobCard from "../../elements/JobCard/JobCard";
import { useQuery } from 'react-query';
import { Jobss } from "../../../hooks/api/UserInformationApi";
// import { JobsSearch } from "../../../hooks/api/UserInformationApi";
// import axios from 'axios'

const JobsContainer = ({filter}) => {
  const { isLoading, data: jobs } = useQuery('jobs', Jobss);
  // eslint-disable-next-line
  // const { data: jobsSearch } = useQuery('jobsSearch', JobsSearch);
// eslint-disable-next-line
  const [results, setResults] = useState([]);

  const searchJobs = (job) =>{
    if(job === ""){
      return jobs?.sort((a,b) =>a-b);
    }
    return jobs?.filter((jobItem)=>{
      const jobName = jobItem.title?.toLowerCase();
      return jobName?.includes(job?.toLowerCase())
      ? jobItem
      : null;
    });
  }

  // FUNCTION TO HANDLE JOB SEARCH
	// const handleJobSearch = () => {
	// 	var jobtitle = jobs?.title;
	// 	const params = new URLSearchParams({ jobTitle: jobtitle }).toString();
	// 	const url = `https://valuejobs.space:8080/api/v1/jobs/search/${jobtitle}?${params}`;

	// 	const config = {
	// 		headers: {
	// 			Authorization:  `Bearer ${JSON?.parse(sessionStorage?.getItem("token"))}`,
	// 		},
	// 	};

	// 	axios
	// 		.get(url, config)
	// 		.then((response) => {
	// 			console.log(response.data);
	// 			setResults(() => [...response.data]);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error.response.data);
	// 		});
	// };

  // useEffect(() => {
  //   handleJobSearch()
  // }, [handleJobSearch])
  
  

  return (
    <div className={styles.JobsContainer}>


      {
        isLoading ? 'loading...':
      searchJobs(filter)?.map((job,i) => {
        return <JobCard key={i} id={job.id} location={job.location} skills={job.skills} type={job.type} description={job.experience} companyName={job.location} price={job.applicationDeadline} jobContent={job.description} jobTitle={job.title} />;
      })} 
      {
        results.length?
        results.map((item) => {
        return <JobCard key={item.id} {...item} />;
      })
  :
  null

      }
    </div>
  );
};

export default JobsContainer;