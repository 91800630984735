import React from 'react'
import styles from '../../../pages/Organization/styles.module.css'
import images from '../../../assets/images/images'

const TopFooter = () => {
  return (
    <div>
       <section className={styles.footer}>
        <div className={styles.disclaimer}>
          <div className={styles.disParadiv}>
            <p className={styles.ptag}>
            Funded by the European Union. Views and opinions expressed are however those
             of the author(s) only and do not necessarily reflect those of the European Union or the
              European Education and Culture Executive Agency (EACEA).
             Neither the European Union nor EACEA can be held responsible for them.
            </p>
          </div>
          <div className={styles.disImg}>
            <img src={images.EuLogo} alt="eulogo" />
          </div>
        </div>
      </section>
    </div>
  )
}

export default TopFooter
