import React,{useState,useEffect} from 'react'
import styles from "./styles.module.css";
import rec from '../../assets/images/Rectangle 228.png'
import InputValue from '../../components/elements/InputBox/InputValue';
import SelectValue from '../../components/elements/InputBox/SelectValue';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation,useNavigate } from 'react-router-dom'
import axios from 'axios';

const EditJob = () => {
   const location = useLocation();
  const { id, } = location.state
  const [loading, setLoading] = useState(false);
 const getJob=()=>{  
    // e.preventDefault();
  setLoading(true);
    axios({
      method: 'get',
      responseType: 'json',
       headers: {
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json',
    'Access-Control-Allow-Headers':
      'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
  },
      url: `https://valuejobs.space:8080/api/v1/jobs/getJob/${id}`,
    })
      .then(response => {
        setLoading(false);
        setUserDetails(response.data)    
      })
      .catch(err => {
        toast.error(err.response.data);
        setLoading(false);
      });
  
 }


    const [info, setUserDetails] = useState([]);


 // eslint-disable-next-line
    useEffect(()=>{
      // eslint-disable-next-line
      getJob()
      // eslint-disable-next-line
    },[])


    //FUNCTION TO GET AND STORE THE USER INFORMATION IN A STATE
    const handleChange = e => {
    setUserDetails({ ...info, [e.target.name]: e.target.value });
    };

     const navigate = useNavigate();

    //FUNCTION TO SUBMIT INFORMATION TO DATABASE
    const handleSubmit=(e)=>{
      e.preventDefault();
      setLoading(true);
      const input ={
      applicationDeadline:info?.applicationDeadline,
      description:info?.description,
      location:info?.location,
      experience:info?.experience,      
      skills:info?.skills,
      title:info?.title,
      type:info?.type
      }
       try {
          axios({
      method: 'put',
      responseType: 'json',
       headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
        'Access-Control-Allow-Headers':
        'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
    },
      url: `https://valuejobs.space:8080/api/v1/jobs/admin/${id}`,
     data: input

    })
        .then(response => {
          setLoading(false);
          toast({
            title: 'Job Updated.',
            position: 'top',
            description: 'Your details have been successfully added.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          navigate('/organization/listed-jobs');
        });
    } catch (e) {
      setLoading(false);
      toast({
        title: e.response.data,
        position: 'top',
        description:
          'It may be from you or us, please ensure your details are correct while we resolve the issue.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    }

  return (
    <div  className={styles.Container}>
        <div className={styles.Container2}>
            <p>Edit Job Offer</p>
            <img src={rec} alt='' />
        </div>
        {/* <div className={styles.Container3}>
            <div className={styles.Box} >
                <p style={{color:'#000', fontWeight:'700'}} >Create job offer </p>
                <p style={{color:'#000'}} >Exciting Opportunity: Join our dynamic team! We're seeking skilled individuals to fill key positions in our company. Competitive salaries, comprehensive benefits, and career growth await you. Be part of an innovative environment where your talents are valued. Apply now and take the first step towards a fulfilling career with us!</p>
            </div>
            <div className={styles.Box}>
                <p style={{color:'#000', fontWeight:'700'}}>Recruit  Top People</p>
                <p style={{color:'#000'}}>"Discover Top Talent: Unlock your organization's potential with our expert recruitment services. We specialize in identifying and attracting top-notch professionals who align perfectly with your company's vision and goals. From extensive candidate screening to seamless onboarding, we ensure you get the best talent to drive your business forward. Let's build greatness together!</p>
            </div>
            <div className={styles.Box}>
                <p style={{color:'#000', fontWeight:'700'}}>Quick & Easy</p>
                <p style={{color:'#000'}}>Quick & Easy: Streamline your tasks with our efficient solutions. Save time and effort as we simplify complex processes, providing user-friendly tools and services. Experience seamless integration and smooth workflows, empowering you to focus on what matters most. Say goodbye to hassle and embrace simplicity with our reliable Quick & Easy solutions.</p>
            </div>
        </div> */}
        <div style={{marginTop:'1.5rem'}} className={styles.Container4} >
            <ToastContainer />
            {/* <div className={styles.InputBox} >
                <InputValue value={info?.name} onChange={handleChange} name='companyName' text='company name' type='text' placeholder='company name'/>
                <InputValue value={info?.email} onChange={handleChange} name='companyEmailAddress' text='company email' type='text' placeholder='company@mail.com'/>
            </div> */}
            <div className={styles.InputBox} >
                <InputValue value={info?.description} onChange={handleChange} name='description' text='description' type='text' placeholder='description'/>
                <InputValue value={info?.experience} onChange={handleChange} name='experience' text='experience' type='text' placeholder='experience'/>
            </div>
            <div className={styles.InputBox} >
                <InputValue value={info?.location} onChange={handleChange} name='location' text='location' type='text' placeholder='location'/>
                <InputValue value={info?.title} onChange={handleChange} name='title' text='title' type='text' placeholder='title'/>
            </div>
            <div className={styles.InputBox} >
                <InputValue value={info?.skills} onChange={handleChange} name='skills' text='skills' type='text' placeholder='skills'/>
                <SelectValue  onChange={handleChange} name='type' value={info?.type} text='type' type='text' placeholder='type'/>
            </div>
            <div className={styles.InputBox} >
                {/* <InputValue value={info?.website} onChange={handleChange} name='website' text='website' type='text' placeholder='website'/> */}
                <InputValue value={info?.applicationDeadline} onChange={handleChange} name='applicationDeadline' text='application deadline' type='date' placeholder='application deadline'/>
            </div>
             <button onClick={handleSubmit}   style={{padding:'1rem',width:'40%',borderRadius:'8px',marginTop:'1rem',marginBottom:'1rem',fontSize:'1.3rem',border:'none',background:'#1d4ed8',color:'#fff',cursor:'pointer',transition:'ease-in'}} >{loading?'loading...':'Submit'}</button>
        </div>
       
    </div>
  )
}

export default EditJob