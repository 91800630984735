import styles from "./styles.module.css";
import { useEffect, useState } from "react";



const Search = ({setFilter}) => {
  const [search, setSearch] = useState('');
  useEffect(()=>{
    setFilter(search);
  },[search, setFilter])
  return (
    <div className={styles.Search}>
      <input type="text" placeholder="Search for a job" value={search} onChange={(e)=>setSearch(e.target.value)}/>
      <button>Search</button>
    </div>
  );
};

export default Search;
