import React, {useState} from 'react'
// import image from '../../assets/images/dash1.png'
// import das from '../../assets/images/dash2.png'
// import dash from '../../assets/images/dash3.png'
import profile from '../../assets/images/profile.png'
import styles from "./styles.module.css";
import InputSelect from '../../components/elements/InputBox/InputSelect';
import InputValue from '../../components/elements/InputBox/InputValue';
import { useMutation, useQueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { OrganizationData } from '../../hooks/api/UserInformationApi';

const Organization = () => {

  const [country, setCountry] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');

    const navigate = useNavigate();

     const changeHandler = value => {
    setCountry(value)
  }

    const queryClient = useQueryClient();
  
     const { mutate, isLoading } = useMutation(OrganizationData, {
    onSuccess: data => {
      console.log(data);
      toast.success('Successful! Profile Created');
      
      navigate('/organization/dashboard');
    },
    onError: () => {
      alert("there was an error")
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    }
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const employee = {
      country:country?.label,
      description:description,
      email:email,
      location:location,
      name:name,
      phone:phone,
      website:website,
    };
    mutate(employee);
  };
  

 
  return (
    <div  className={styles.OrgainizationContainer}>
        {/* <img className={styles.Image} src={image} alt='' /> */}
         {/* <div className={styles.Imgcontainer}>
          <img className={styles.Das} src={das} alt='' />
          <img className={styles.Dash} src={dash} alt='' />
        </div>  */}
        <div className={styles.OrgainizationContainer2} >
            <p>Organization</p>
            <p> Create Profile </p>
            <img className={styles.ProfileImage} src={profile} alt='profile' />
        </div>
        <ToastContainer />
        <div className={styles.Container3}>
          <InputValue text='Name' value={name} onChange={(e)=>setName(e.target.value)} placeholder="Name"  type="text" />
          <InputValue text='Email' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email"  type="text" />
          <InputValue text='Website' value={website} onChange={(e)=>setWebsite(e.target.value)} placeholder="Website"  type="text" />
          <InputValue text='Location' value={location} onChange={(e)=>setLocation(e.target.value)} placeholder="Location"  type="text" />
            <InputSelect
        label="Country"
        text='Country'
        placeholder="Country"
        value={country}
        onChange={changeHandler}
      />
          <InputValue text='Phone' value={phone}  onChange={(e)=>setPhone(e.target.value)} placeholder="Phone"  type="text" />
          <InputValue text='Organization Description' value={description} onChange={(e)=>setDescription(e.target.value)} placeholder="Story of your Organization"  type="text" />
        <button onClick={onSubmit}  style={{padding:'1rem',width:'30%',borderRadius:'8px',marginTop:'1rem',fontSize:'1.3rem',border:'none',background:'#1d4ed8',color:'#fff',cursor:'pointer',transition:'ease-in'}} >{isLoading?'loading...':'Create Profile'}</button>
        
        </div>
    </div>
  )
}

export default Organization