import styles from "./styles.module.css";

const SearchInput = ({ placeholder, backgroundColor, color }) => {
  return (
    <div
      className={styles.SearchInput}
      style={{ backgroundColor: backgroundColor, color: color }}
    >
      <input type="text" placeholder={placeholder} />
      <button>Search</button>
    </div>
  );
};

export default SearchInput;
